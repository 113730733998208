<template>
  <div class="iot-login-bg">
    <a-row
      type="flex"
      justify="space-around"
      align="middle"
      class="iot-login"
    >

      <a-col :span="14" class="iot-login-left">
        <img src="@/assets/images/login.jpg">
      </a-col>

      <a-col :span="6" class="iot-login-center">
        <div class="iot-login-form" style="background-color: #fff; padding: 20px; box-sizing: border-box; border-radius: 10px">

          <h1 class="iot-login-title" style="text-align: center">
            物联网连接管理平台
          </h1>
          <div class="login-border">
            <div class="login-content">
              <!--              <div class="iot-login-welcome">-->
              <!--                欢迎登录-->
              <!--              </div>-->
              <br>
              <a-form
                id="components-form-demo-normal-login"
                :form="form"
                @submit="handleSubmit"
                v-show="!isQrcLogin"
              >
                <a-form-item>
                  <a-input
                    v-decorator="['username', {
                      normalize: this.$lodash.trim,
                      rules: [
                        { required: true, message: '请输入登录账号' }
                      ]
                    }]"
                    placeholder="账号"
                  >
                    <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)" />
                  </a-input>
                </a-form-item>

                <a-form-item>
                  <a-input
                    v-decorator="['password', {
                      rules: [
                        { required: true, message: '请输入登录密码' }
                      ]
                    }]"
                    type="password"
                    placeholder="密码"
                  >
                    <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)" />
                  </a-input>
                </a-form-item>

                <a-form-item>
                  <a-row :gutter="8">
                    <a-col :span="18">
                      <a-input
                        placeholder="验证码"
                        v-decorator="['captcha', {
                          normalize: this.$lodash.trim,
                          rules: [{ required: true, message: '请输入验证码' }]
                        }]"
                      />
                    </a-col>
                    <a-col :span="6">
                      <a-tooltip>
                        <template slot="title">
                          点击刷新验证码
                        </template>

                        <a-spin :spinning="loadingCaptchaImg">
                          <img
                            v-if="captchaId.length > 0"
                            class="captcha-img iotplt-cursor-pointer"
                            @click="reloadCaptcha"
                            @load="captchaImgLoaded"
                            :src="captchaImgUrl"
                          >
                        </a-spin>
                      </a-tooltip>
                    </a-col>
                  </a-row>
                </a-form-item>

                <a-form-item style="display: flex;align-content: center; justify-content: center">
                  <a-button
                    :loading="submitting"
                    type="primary"
                    html-type="submit"
                    class="iot-login-form-button"
                    style="width: 200px;"
                    size="large"
                  >
                    登录
                  </a-button>
                </a-form-item>
                <a @click="changeQrcLogin" style="display:none;">扫码登录</a>
              </a-form>

              <a-form v-show="isQrcLogin">
                <a-form-item style="text-align:center;">
                  <iframe :src="qrcUrl" scrolling="no" width="300px" height="330px" frameborder="0">&nbsp;</iframe>
                </a-form-item>
                <a @click="changeQrcLogin">账号登录</a>
              </a-form>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { newCaptcha, reloadCaptcha } from '@/api/captcha'

export default {
  name: 'Login',
  data() {
    // 节流
    this.calmDown = this.$lodash.throttle(this.calmDown, 4000)
    return {
      redirect: undefined,
      captchaId: '',
      captchaImgUrl: '',
      loadingCaptchaImg: false,
      submitting: false,
      busy: false, // 为 true 时提示操作频繁
      isQrcLogin: false,
      qrcUrl: '',
      form: this.$form.createForm(this, { name: 'login' })
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect
      }
    }
  },
  created() {
    this.newCaptchaId()

    this.qrcUrl = 'https://open.weixin.qq.com/connect/qrconnect?appid=' +
      process.env.VUE_APP_WC_OPEN_APP_ID + '&redirect_uri=' + process.env.VUE_APP_WC_OPEN_LOGIN_CALLBACK +
      '&response_type=code&scope=snsapi_login&state=qrclogin&login_type=jssdk&self_redirect=default'
  },
  methods: {
    ...mapActions(['Login']),

    // 获取新的验证码id
    newCaptchaId() {
      this.loadingCaptchaImg = true
      newCaptcha().then(res => {
        if (res.code === 0) {
          this.captchaId = res.data.id
          this.fetchCaptchaImgUrl()
        }
      })
    },

    calmDown() {
      this.busy = false
    },

    tooBusyMessage() {
      this.$message.error('操作太频繁，请稍后再试')
    },

    // 检测是否频繁操作
    checkBusy() {
      this.calmDown()

      if (this.busy) {
        this.tooBusyMessage()
        return true
      } else {
        this.busy = true
        return false
      }
    },

    // 根据验证码id刷新验证码
    reloadCaptcha() {
      if (this.loadingCaptchaImg || this.submitting) {
        this.tooBusyMessage()
        return
      }

      if (this.checkBusy()) {
        return
      }

      this.loadingCaptchaImg = true
      reloadCaptcha(this.captchaId).then(res => {
        if (res.code === 0) {
          this.fetchCaptchaImgUrl()
        }
      })
    },

    // 验证码图片加载完成
    captchaImgLoaded() {
      this.loadingCaptchaImg = false
    },

    // 加载验证码图片url
    fetchCaptchaImgUrl() {
      this.captchaImgUrl = `${process.env.VUE_APP_API_BASE_URL}/captcha/${this.captchaId}?v=${Math.random()}`
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          if (this.loadingCaptchaImg) {
            this.tooBusyMessage()
            return
          }

          if (this.checkBusy()) {
            return
          }

          this.submitting = true
          this.Login(Object.assign({ captcha_id: this.captchaId }, values)).then((res) => {
            this.$router.push({ path: this.redirect || '/' }).catch(() => {
            })
            this.submitting = false
          }).catch(() => {
            // 重新生成验证码id
            this.newCaptchaId()
            this.form.setFieldsValue({ captcha: '' })
            this.submitting = false
          })
        }
      })
    },

    changeQrcLogin() {
      if (this.isQrcLogin) {
        this.isQrcLogin = false
      } else {
        this.isQrcLogin = true
      }
    }
  }
}
</script>
<style lang="less">
.iot-login-bg {
  background-color: #000;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  padding: 20px 100px 100px 100px;

  .iot-login {
    width: 100%;

    .iot-login-left img {
      width: 100%;
    }

    .iot-login-right {
      display: flex;
      padding: 40px;

      .iot-login-form {
        flex-basis: 400px;
        width: 90%;
        .iot-login-title {
          color: @primary-color;
          text-align: center;
        }

        .login-border {
          margin:0 auto;
          border:2px solid @primary-color;
          border-radius:8px;
          .login-content {
            padding: 30px 20px 30px 20px;

            .iot-login-welcome {
              font-size: 20px;
              text-align: center;
            }

            .iot-login-form-button {
              width: 100%;
            }
          }
        }
      }
    }

    .captcha-img {
      width: 100%;
      height: 100%;
      float: right;
    }
  }
}
</style>
